module.exports = [
  {
    "name": "Westside Nannies",
    "website": "https://www.westsidenannies.com/",
    "email": "katie@westsidenannies.com",
    "city": "Beverly Hills",
    "phone": "310-359-5300",
    "state": "California"
  },
  {
    "name": "The Nanny League",
    "website": "https://www.thenannyleague.com/",
    "email": "anna@thenannyleague.com",
    "city": "Beverly Hills",
    "phone": "310-893-9355",
    "state": "California"
  },
  {
    "name": "Pacific Elite Nannies",
    "website": "https://pacificelitenannies.com/",
    "email": "info@pacificelitenannies.com",
    "city": "Huntington Beach",
    "phone": "714-716-7036",
    "state": "California"
  },
  {
    "name": "Huntington Nannies",
    "website": "https://www.huntingtonnannies.com/",
    "email": "info@huntingtonnannies.com",
    "city": "Los Angeles",
    "phone": "844-374-5808",
    "state": "California"
  },
  {
    "name": "Stanford Park Nannies",
    "website": "https://spnannies.com/",
    "email": "info@spnannies.com",
    "city": "Menlo Park",
    "phone": "650-462-4580",
    "state": "California"
  },
  {
    "name": "Stanford Park Nannies",
    "website": "https://spnannies.com/",
    "email": "info@spnannies.com",
    "city": "Menlo Park",
    "phone": "650-462-4580",
    "state": "California"
  },
  {
    "name": "My Asian Nanny Inc",
    "website": "https://myasiannanny.com/",
    "email": "cs@myasiannanny.com",
    "city": "Monterey Park",
    "phone": "626-537-1191",
    "state": "California"
  },
  {
    "name": "O.C. Nannies & Newport Domestics",
    "website": "https://ocnannies.com/",
    "email": "support@ocnannies.com",
    "city": "Newport Beach",
    "phone": "949-721-0878",
    "state": "California"
  },
  {
    "name": "South Bay Nannies",
    "website": "http://www.southbaynannies.com/",
    "email": "nigel@southbaynannies.com",
    "city": "Redondo Beach",
    "phone": "424-247-8507",
    "state": "California"
  },
  {
    "name": "Capitol Park Nannies",
    "website": "https://capitolparknannies.com/contact/",
    "email": "rurtiaga@cpnannies.com",
    "city": "Roseville",
    "phone": "916-677-4273",
    "state": "California"
  },
  {
    "name": "Whine Country Nanny and Babysitter Services",
    "website": "https://www.whinecountrynanny.com/",
    "email": "Jordan.ballard10@yahoo.com ",
    "city": "Sacramento",
    "phone": "707-304-1421",
    "state": "California"
  },
  {
    "name": "Loving Nannies Agency-Nannies, Baby Nurses, Housekeepers & More",
    "website": "https://www.lovingnannies.com/",
    "email": "nanny@lovingnannies.com",
    "city": "San Diego",
    "phone": "760-943-6866",
    "state": "California"
  },
  {
    "name": "Seaside Nannies Inc",
    "website": "https://seasidestaffingcompany.com/",
    "email": "info@seasidestaffingcompany.com",
    "city": "San Diego",
    "phone": "619-881-8887",
    "state": "California"
  },
  {
    "name": "Trusted Child Care - Babysitters & Nannies",
    "website": "https://usetrusted.com",
    "email": "ai@usetrusted.com",
    "city": "San Francisco",
    "phone": "844-987-8783",
    "state": "California"
  },
  {
    "name": "Genuine Nannies LLC",
    "website": "https://www.genuinenannies.com/",
    "email": "marie@genuinenannies.com",
    "city": "San Francisco",
    "phone": "415-310-8954",
    "state": "California"
  },
  {
    "name": "Joanna's Nannies San Francisco Bay Area",
    "website": "https://joannasnannies.com/",
    "email": "owner@joannasnannies.com",
    "city": "Santa Rosa",
    "phone": "415-834-8216",
    "state": "California"
  },
  {
    "name": "Educated Nannies",
    "website": "https://www.educatednannies.com/",
    "email": "ryan@educatednannies.com",
    "city": "Sherman Oaks",
    "phone": "310-857-4985",
    "state": "California"
  },
  {
    "name": "Educated Nannies",
    "website": "https://www.educatednannies.com/",
    "email": "ryan@educatednannies.com",
    "city": "Sherman Oaks",
    "phone": "310-857-4985",
    "state": "California"
  },
  {
    "name": "LA Placements",
    "website": "https://www.laplacements.com/contact-2/",
    "email": "Marcia@laplacements.com",
    "city": "Studio City",
    "phone": "818-232-7688",
    "state": "California"
  },
  {
    "name": "The Nanny Matchmaker",
    "website": "https://www.thenannymatchmaker.com/",
    "email": "info@thenannymatchmaker.com",
    "city": "West Hollywood",
    "phone": "818-850-5981",
    "state": "California"
  }
]
